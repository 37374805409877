import PropTypes from "prop-types"
import React from "react"
import { openPopupWidget } from "react-calendly";
import { Link } from "gatsby"

const Ancillary = ({ email, services, telephone, telephoneLink }) => (
    <aside className={"relative overflow-hidden ancillary"}>

        <div className={"border-t-2 bg-white bg-opacity-50"}>
            <div
                className="max-w-screen-xl mx-auto py-12 px-6 md:px-12 lg:py-16 lg:flex lg:items-center lg:justify-between">
                <h2 className="lg:flex-auto lg:pr-6 text-3xl leading-9 tracking-tight font-display text-gray-800 sm:text-4xl sm:leading-10">
                    Ready to talk?
                    <br/>
                    <span className="text-indigo-600">Book a call with a certified AWS cloud specialist today.</span>
                </h2>
                <div className="lg:flex-auto mt-8 lg:mt-0">
                    <div className="flex lg:flex-col">
                        <a href="https://calendly.com/mtwalsh/30min" onClick={(e) => {
                            e.preventDefault();
                            openPopupWidget({
                                url: 'https://calendly.com/mtwalsh/30min',
                                pageSettings: {
                                    'hideEventTypeDetails': true,
                                    'hideLandingPageDetails': true,
                                }
                            });
                            return false;
                        }}
                           className="flex-1 xl:flex-none lg:mb-2 rounded-md shadow text-center whitespace-no-wrap px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring transition duration-150 ease-in-out">
                            Book a call now
                        </a>
                        <Link
                            to={'/contact'}
                            className={"flex-1 ml-3 xl:flex-none lg:ml-0 rounded-md shadow text-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-secondary hover:bg-orange-500 focus:outline-none focus:ring transition duration-150 ease-in-out"}
                        >Contact us</Link>
                    </div>
                </div>
            </div>
        </div>

        <div className={"bg-primary bg-opacity-75"}>
            <div className={"max-w-screen-xl mx-auto py-12 lg:py-16 px-6 md:px-12"}>
                <div className={"grid grid-cols-12"}>
                    <div className={"col-span-12 mb-6 md:mb-8 lg:col-span-7 lg:mb-0"}>
                        <h2 className={"text-secondary leading-8 sm:leading-10 text-base font-display font-bold uppercase"}>About</h2>
                        <p className={"text-white prose prose-lg"}>Think of Opworks as an extension of your own IT team for all things AWS cloud. We will design, build and maintain the foundations your organisation needs in the AWS cloud to compete with the best in your field. Our aim is to deliver a solid infrastructure that's resilient, secure and scalable, whilst also reducing costs and improving the metrics that matter to you.</p>
                    </div>

                    <div className={"col-span-12 mb-6 sm:col-span-6 sm:mb-0 lg:col-span-2 lg:col-start-9"}>
                        <h2 className={"text-secondary leading-8 sm:leading-10 text-base font-display font-bold uppercase"}>Services</h2>
                        <ul className={"ancillary-services text-lg"}>
                            {services.map(link => (
                                <li className={"mb-1 text-white ancillary-service"} key={link.title}>{link.title}</li>
                            ))}
                        </ul>
                    </div>

                    <div className={"col-span-12 sm:col-span-6 lg:col-span-2 lg:col-start-11"}>
                        <h2 className={"text-secondary leading-8 sm:leading-10 text-base font-display font-bold uppercase"}>Contact</h2>
                        <ul>
                            <li className={"mb-1"}>
                                <a href={telephoneLink} className={"text-white text-lg hover:text-secondary focus:text-secondary"}>{telephone}</a>
                            </li>
                            <li>
                                <a href={`mailto:${email}`} className={"text-white text-lg hover:text-secondary focus:text-secondary"}>{email}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    </aside>
)

Ancillary.propTypes = {
    email: PropTypes.string,
    telephone: PropTypes.string,
    telephoneLink: PropTypes.string
}

export default Ancillary
